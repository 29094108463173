<template>
    <ValidationObserver ref="observer" v-slot="{ validate  }">
        <b-form @submit.stop.prevent="validate().then(Edit)">
            <div class="w-full flex flex-col justify-between">
                <GeneralFarmaInformation v-model="viewModel" class="mt-4"/>
            </div>
            <div class="flex mt-3">
                <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
            </div>
            <footer class="w-full flex items-center justify-start gap-5 my-10">
            <Button
                class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
                buttonText="Confirmar"
                type="submit"
            />
            <Button
                class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
                buttonText="Cancelar"
                :onClick="redirectUrl"
            />
            </footer>
            <Modal
            v-if="modalIsOpen"
            :modalIsOpen.sync="modalIsOpen"
            :closeModal="closeModal"
            :modalText="$t('commons.modalTextEdit')"
            />

            <Modal
            v-if="modalError"
            :modalIsOpen.sync="modalError"
            :closeModal="closeModal"
            :modalText="$t('commons.modalErrorCreate')"
            />
            <Modal
            v-if="modalErroSalvar"
            :modalIsOpen.sync="modalErroSalvar"
            :closeModal="closeModal"
            :modalText="$t('commons.modalErroSalvar')"
            />
        </b-form>
    </ValidationObserver>
</template>

<script>
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

import GeneralFarmaInformation from './GeneralFarmaInformation.vue';
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
  name: 'ManageEnsaioCreate',
  components: {
      BForm,
      ValidationObserver,
      GeneralFarmaInformation,
      Button,
      Modal,
  },
  data() {
      return {
          modalIsOpen: false,
          modalError: false,
          modalErrorMessage: null,
          modalErroSalvar:false,
          viewModel: {
            id: null,
            nomeFarmacopeia: null,
            descricaoFarmacopeia: null,
          }
      }
  },
  created () {
    this.RecuperarFarmacopeia(this.$router.currentRoute.params.id);
  },
  methods: {
    RecuperarFarmacopeia (id) {
      this.$http({
        url: `/Farmacopeia/obter/${id}`,
        method: "Get"
      }).then((response) => {
        this.viewModel = {
            id: response.data.id,
            nomeFarmacopeia : response.data.nomeFarmacopeia,
            descricaoFarmacopeia: response.data.descricaoFarmacopeia,
        }
      })
    },
      redirectUrl() {
        return this.$router.push("/groupProducts/farmacopeia");
      },
      closeModal(event) {
          if (event.target === event.currentTarget) {
              this.modalIsOpen = false;
              this.modalError = false;
              this.modalErroSalvar = false;
              window.scrollTo({
              top: 0,
              behavior: "smooth",
              });
          }
      },
      openModal () {
        this.modalIsOpen = true;
    },
      async Edit () {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            this.modalError = true;
            return
        }
        this.$http({
        url: `/Farmacopeia/editar`,
        data: this.viewModel,
        params: { id: this.viewModel.id },
        method: "POST"
        }).then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push("/groupProducts/farmacopeia"), 3000);
            }).catch(() => this.modalErroSalvar = true)
        },
  },
}
</script>
