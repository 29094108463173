<template>
    <b-form>
        <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div class="col-span-8">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 lg:col-span-12">
                        <input-required
                        v-model="value.nomeFarmacopeia"
                        label="Nome"
                        :rules="{required:true ,max:50}"
                        max="50"
                        />
                    </div>
                    <div class="col-span-12 xl:col-span-12">
                        <input-required
                        v-model="value.descricaoFarmacopeia"
                        :rules="{required:true, max:50}"
                        label="Observação"
                        max="50"
                        />
                    </div>
                </div>
            </div>
        </div>
    </b-form>
</template>

<script>
import { BForm } from "bootstrap-vue";

import InputRequired from "@core/components/commons/inputs/InputRequired";

export default {
    name: 'GeneralEnsaioInformation',
    components: {
        BForm,
        InputRequired,
    },
    props: {
        value: {
            type: Object,
        }
    }
}
</script>
  